import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        platform: 'desktop',
        mobilePlatform: null,
        windowSize: [0, 0],
        nav: [
            {to: "user-acquisition", name: "User acquisition"},
            {to: "in-app-network", name: "In-app Network"},
            {to: "contacts", name: "Contacts"},
        ],
        partnersIcons: [
            "we-trusted/partner-1.png",
            "we-trusted/partner-2.png",
            "we-trusted/partner-4.png",
            "we-trusted/partner-6.png",
            "we-trusted/partner-7.png",
            "we-trusted/partner-14.jpg",
            "we-trusted/partner-9.png",
            "we-trusted/partner-10.png",
            "we-trusted/partner-11.png",
            "we-trusted/partner-13.jpg",
        ],
        description: [
            {
                img: "star.png",
                title: "MONTERIZO IN-APP NETWORK",
                text: "Works with the largest advertising platforms and publishers. We have generated more than 30 million app downloads and attracted more than 2 million active users",
                list: [
                    "We filter traffic using a modern anti-fraud system: Protect 360",
                    "Indicators: CTIT, CR, uninstall rates, and others",
                    "High LTV rates of our traffic",
                    "A special traffic distribution algorithm - DSP",
                ],
                classPadding: "Btn--padding"
            },
            {
                iconPadding: '2.3%',
                iconPaddingMobile: '4.3%',
                img: "play.png",
                title: "User acquisition",
                text: "We have 30 UA managers in-house and our own optimization technology for acquiring high quality users and ROI",
                link: "user-acquisition",
                classImg: "Img--size"
            },
        ],
        advantagesList: [
            {
                title: "",
                indicator: "8",
                text: "years on the market",
                class: "years"
            },
            {
                title: "we work",
                indicator: "24/7",
                text: "for results",
                class: "work"
            },
            {
                title: "More than",
                indicator: "100 000",
                text: "new customers per year",
                class: "ftd"
            },
            {
                title: "team",
                indicator: "30+",
                text: "professionals, who love mobile",
                class: "team"
            },
        ],
        awardsList: [
            {
                ranking: "2",
                text: "Growth Index in the region of<br> Eastern Europe in the <br> Android category",
                class: "award1"
            },
            {
                ranking: "3",
                text: "Growth Index in the region of<br> Western Europe in the <br> Android category",
                class: "award2"
            },
            {
                ranking: "4",
                text: "Retention Index (Volume<br> Ranking) in the region of Eastern<br> Europe in the category of Life & <br>Culture for iOS",
                class: "award3"
            },
            {
                ranking: "7",
                text: "Retention Index (Power<br> Ranking) in the region of Eastern<br> Europe in the category of Life &<br> Culture for iOS",
                class: "award4"
            },
            {
                ranking: "29",
                text: "IAP Index (Power Ranking) in<br> the category for<br> Android OS",
                class: "award5"
            },
        ],
        contacts: {
            allRequirements: [
                {title: "ceo@monterizo-cyprus.com", link: "mailto:ceo@monterizo-cyprus.com"},
            ]
        },
        sources: [
            "additional-traffic-logo/facebook.svg",
            "additional-traffic-logo/inst.png",
            "additional-traffic-logo/snapchat.svg",
            "additional-traffic-logo/unity.svg",
            "additional-traffic-logo/Vungle-app.svg",
            "additional-traffic-logo/adcolony-icon.svg",
            "additional-traffic-logo/Apple_logo_black.svg",
            "additional-traffic-logo/applovin-app.svg",
            "additional-traffic-logo/Baidu-01.svg",
            "additional-traffic-logo/bigo-ads-app.svg",
            "additional-traffic-logo/criteo-app.svg",
            "additional-traffic-logo/google-ads-app.svg",
            "additional-traffic-logo/google-display-and-video-ads.svg",
            "additional-traffic-logo/ironSource-app.svg",
            "additional-traffic-logo/ironSourceAura-app.svg",
            "additional-traffic-logo/MOLOKO.svg",
            "additional-traffic-logo/ShareIt-app.svg",
            "additional-traffic-logo/Simeji-wh-app.svg",
            "additional-traffic-logo/tiktok.svg",
            "additional-traffic-logo/twitter-app.svg",
            "additional-traffic-logo/xiaomi.svg",
        ],
        advantages: [
            {
                icon: "automation.png",
                title: "automation",
                text: "Traffic optimization technologies allow you to get the maximum return on investment",
                class: "automation",
            },
            {
                icon: "star-small.png",
                title: "Creativity",
                text: "The creative department knows what approach is needed to attract and “hook” your target audience",
                class: "creativity",
            },
            {
                icon: "shield.png",
                title: "Anti-Fraud",
                text: "Our own anti-fraud system protects our customers from fraud and low-quality traffic",
                class: "anti-fraud",
            },
            {
                icon: "activity.png",
                title: "Quality and Volumes",
                text: "Due to the accumulated expertise, we generate large volumes of high-quality traffic in a short time",
                class: "quality",
            },
            {
                icon: "game.png",
                title: "Client Service",
                text: "A transparent approach to work, weekly reports, and a dedicated manager allow us to provide the highest level of service",
                class: "client",
            },
        ],
        clientsJourney: [
            {text: "1. <br>The user sees an ad for the app in one of our sources", class: "first"},
            {text: "2. Clicks on the ad and goes to the marketplace page", class: "second"},
            {
                text: "3. Downloads the application",
                class: "third"
            },
            {text: "4. Opens the application and registers", class: "fourth"},
        ],
        clients: [
            {
                left: "client1.png",
                right: [
                    "client2.png",
                    "client3.png",
                ]
            },
            {
                left: "client4.png",
                right: [
                    "client7.png",
                    "client10.png",
                ]
            },
            {
                left: "client11.png",
                right: [
                    "client13.png",
                    "client11.png",
                ]
            },
        ]
    },
    mutations: {
        setPlatform(state, platform) {
            state.platform = platform
        },
        setMobilePlatform(state, mobilePlatform) {
            state.mobilePlatform = mobilePlatform;
        },
    },
    actions: {},
    getters: {
        platform: state => state.platform,
        mobilePlatform: state => {
            return state.mobilePlatform
        },
        isMobile: state => state.platform === 'mobile',
        isTablet: state => state.platform === 'tablet',
        isDesktop: state => state.platform === 'desktop',
        isLandscape: state => state.windowSize[0] > state.windowSize[1],
        isPortrait: (_, getters) => !getters.isLandscape,
        getNav(state) {
            return state.nav
        },
        getPartnersIcons(state) {
            return state.partnersIcons
        },
        getDescription(state) {
            return state.description
        },
        getAdvantagesList(state) {
            return state.advantagesList
        },
        getAwardsList(state) {
            return state.awardsList
        },
        getSocials(state) {
            return state.socials
        },
        getContacts(state) {
            return state.contacts
        },
        getCases(state) {
            return state.cases
        },
        getSources(state) {
            return state.sources
        },
        getAdvantages(state) {
            return state.advantages
        },
        getClientsJourney(state) {
            return state.clientsJourney
        },
        getClients(state) {
            return state.clients
        }
    },
    modules: {}
})
