<template>
  <router-link
      to="/"
      class="Logo"
  >
    <slot/>
  </router-link>
</template>

<script>

export default {
  name: "Logo",
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.Logo {
  width: 10.48%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  .mobile &,
  .landscape &,
  .portrait & {
    width: 29.89%;
  }
}
</style>