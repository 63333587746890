<template>
  <div
      id="app"
      :class="platformClass"
  >
    <MainMenu/>
    <router-view/>
    <Footer/>
  </div>
</template>

<style lang="scss">

</style>
<script>
import MainMenu from "./components/MainMenu";
import Footer from "@/components/Footer";
import {mapGetters} from "vuex";

export default {
  components: {
    MainMenu,
    Footer,
  },
  computed: {
    ...mapGetters({
      platform: 'platform',
      isMobile: 'isMobile',
      isTablet: 'isTablet',
      isDesktop: 'isDesktop',
      mobilePlatform: 'mobilePlatform',
      isLandscape: 'isLandscape'
    }),
    platformClass() {
      switch (this.platform) {
        case 'mobile':
        case 'tablet':
          return [this.platform, this.mobilePlatform, this.isLandscape ? 'landscape' : 'portrait'];
        default:
          return this.platform;
      }
    },

  }
}
</script>