<template>
  <section class="Description">
    <div class="Wrapper">
      <div
          class="Description-Cards"
          :class="{'Description-Cards--padding': showText && isMobile}"
      >
        <div
            v-for="(info, $info) in description"
            :key="$info"
            class="Description-Card"
        >
          <img
              :src="require(`@/assets/images/${info.img}`)"
              alt="icon"
              class="Description-CardIcon"
              :class="info.classImg"
              :style="{paddingTop: isMobile ? info.iconPaddingMobile : info.iconPadding}"
          >
          <h4
              class="Description-CardTitle"
              v-html="info.title"
          ></h4>
          <p
              class="Description-CardText"
              v-html="info.text"
          ></p>
          <div v-if="info.link" class="Description-CardBottom Description-CardBottom--margintop">
            <transition name="fade" mode="out-in">
              <router-link
                  v-show="!showText || isDesktop"
                  :to="info.link"
                  class="Btn Btn--white Description-CardBtn"
              >
                More
              </router-link>
            </transition>
          </div>
          <div v-else class="Description-CardBottom">
            <transition name="fade" mode="out-in">
              <button
                  v-show="!showText"
                  class="Btn Btn--white Description-CardBtn"
                  :class="info.classPadding"
                  @click="showText = !showText"
              >
                More
              </button>
            </transition>
            <transition name="fade" mode="out-in">
              <div
                  class="Description-CardList"
                  @click="showText = !showText"
                  :class="{'Description-CardList--animation': showText}"
                  v-click-outside="onClickOutside"
              >
                <ul>
                  <li
                      v-for="(item, $item) in info.list"
                      :key="$item"
                      class="Description-CardItem"
                  >
                    {{ item }}
                  </li>
                </ul>
                <button
                    v-show="showText"
                    class="Btn Btn--white Description-CardBtn Hide"
                    :class="info.classPadding"
                    @click.stop="showText = !showText"
                >
                  Hide
                </button>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Description",
  data() {
    return {
      showText: false,
    }
  },
  computed: {
    ...mapGetters({
      description: 'getDescription',
      isMobile: 'isMobile',
      isDesktop: 'isDesktop'
    })
  },
  methods: {
    onClickOutside(e) {
      if (!e.target.closest('.Description')) this.showText = false;
    },
  }
}
</script>

<style lang="scss">
.Description {
  padding: 0 0 3.334%;

  &-Cards {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: center;

    &--padding {
      padding: 0 0 16vw 0;
    }
  }

  &-Card {
    width: percentage(660/1708);
    height: 31.4vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .desktop & {
      position: relative;
      justify-content: space-between;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      width: 42.69%;
      height: 67.4vw;
    }
  }

  &-CardIcon {
    width: 24.8%;
    max-width: 100%;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 30.4%;
      margin-bottom: 2vw;
    }
  }

  &-CardTitle {
    font-weight: 400;
    font-size: 2.344vw;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 2.58vw;
      padding: 0 5vw;
      font-size: 3.272vw;
    }
  }

  &-CardText {
    padding: 0 2.6em;
    font-size: 1.3vw;

    .mobile &,
    .landscape &,
    .portrait & {
      padding: 0 1vw;
      font-size: 2.804vw;
    }
  }

  &-CardBtn {
    z-index: 3;
    padding: .48em 3.58em .44em;

    .mobile &,
    .landscape &,
    .portrait & {
      padding: 0.32em 1.71em;
    }
  }

  &-CardBottom {
    width: 100%;
    min-height: 6.25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4.65%;

    .desktop {
      position: relative;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      &--margintop {
        margin-top: 10.3vw;
      }
    }
  }

  &-CardList {
    position: absolute;
    font-size: 1.3vw;
    color: var(--color-text-main1);
    cursor: pointer;
    opacity: 0;

    &--animation {
      opacity: 1;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 2.804vw;

      .Hide {
        display: block;
        position: relative;
        left: 50%;
        margin: 3vw 0 0;
        transform: translate(-50%,0);
      }
    }

    .Hide {
      display: none;
    }
  }


  &-CardItem {
    position: relative;
    padding-left: 2.33%;
    display: inline-block;
    text-align: center;

    &:before {
      position: absolute;
      content: "";
      width: .15em;
      height: .15em;
      background-color: var(--color-bg-main1);
      border-radius: 50%;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  .Img--size {
    width: 15.65%;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 20.8%;
    }
  }
}

</style>