<template>
  <section class="About">
    <h2 class="About-Title">
      MONTERIZO —
    </h2>
    <div class="About-Subtitle">
      Is a technological mobile marketing agency which help businesses find new customers
    </div>
    <Partners
        :partners-title="'We are trusted by'"
        :slider-info="getPartnersIcons"
        class="About-Partners"
    />
    <div class="About-Text Colored">
      8 years of expertise help us to dive into the project and develop and implement
      a comprehensive strategy to attract new paying users
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Partners from "@/components/Partners";

export default {
  name: "About",
  components: {Partners},
  computed: {
    ...mapGetters(['getPartnersIcons'])
  }
}
</script>

<style lang="scss">
.About {
  &-Title {
    font-weight: 400;
    font-size: 3.6vw;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 5.85vw;
    }
  }

  &-Subtitle {
    margin-bottom: 1.67%;
    font-weight: 300;
    font-size: 2.813vw;
    line-height: 1.5;
    text-align: center;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 3.505vw;
    }
  }

  &-Text {
    width: percentage(1090 / 1920);
    margin: 0 auto;
    font-weight: 400;
    font-size: 1.3vw;
    line-height: 1.5;
    text-align: center;

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 2.804vw;
    }
  }

  &-Partners {
    margin-bottom: 1.67%;

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 4.673vw;
    }
  }
}

</style>