import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const InAppNetworkPage = () => import(/* webpackChunkName: "InAppNetworkPage" */ '../views/InAppNetworkPage.vue')
const Contacts = () => import(/* webpackChunkName: "Contacts" */ '../views/Contacts.vue')
const UserAcquisition = () => import(/* webpackChunkName: "UserAcquisition" */ '../views/UserAcquisition.vue')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/in-app-network',
        name: 'inappnetworkpage',
        component: InAppNetworkPage
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts
    },
    {
        path: '/user-acquisition',
        name: 'user-acquisition',
        component: UserAcquisition
    },
    {
        path: '*',
        redirect() {
            return '/'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'
            };
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth'
        };
    },
    routes
})

export default router
