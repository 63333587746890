import '@/assets/styles/main.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import VueScreen from 'vue-screen';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css'
import VModal from 'vue-js-modal'
import detectPlatform from '../src/assets/plugins/detectPlatform';
import vClickOutside from 'v-click-outside'
import VueMeta from 'vue-meta'

Vue.use(VueAwesomeSwiper);
Vue.use(VueScreen);
Vue.use(VModal);
Vue.use(vClickOutside);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

detectPlatform({ store });

Vue.config.productionTip = false

const requireComponent = require.context(
    './components',
    false,
    /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
      camelCase(
          fileName
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')
      )
  )

  Vue.component(
      componentName,
      componentConfig.default || componentConfig
  )
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
