<template>
  <footer class="Footer">
    <div class="Footer-Wrapper">
      <div class="Footer-DocsList">
        <a
          v-for="doc in docs"
          :href="`${publicPath}pdf/${doc.path}`"
          :key="doc.name"
          class="Footer-Policy"
          target="_blank"
          >{{ doc.title }}
        </a>
      </div>
      <Logo class="Footer-Logo">
        <img :src="require(`@/assets/images/logo-footer.png`)" alt="logo" />
      </Logo>
      <div class="Footer-Copy">Copyright © 2022 www.monterizo-cyprus.com</div>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  name: "Footer",
  components: { Logo },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      docs: [
        {
          path: "Monterizo_Compliance_Policy_15.09.2022.pdf",
          title: "Compliance Policy"
        },
        {
          path: "Monterizo_Privacy_Policy_15.09.2022.pdf",
          title: "Privacy Policy"
        },
        {
          path: "Monterizo_TERMS_OF_SERVICE_15.09.2022.pdf",
          title: "Terms of service"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.Footer {
  padding: 2.343vw 0 1.82vw;
  background: var(--color-bg-footer);

  .mobile &,
  .landscape &,
  .portrait & {
    padding: 2vw 0 4vw;
  }

  &-Wrapper {
    position: relative;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 72.917vw;
    margin: 0 auto;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 84vw;
    }
  }

  &-Logo {
    width: 10.16vw;
    margin-right: 5.3vw;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 26vw;
      margin-right: 0;
    }
  }

  &-Copy {
    position: absolute;
    right: 5.5vw;
    bottom: -1vw;
    font-family: "PTSans", sans-serif;
    font-weight: 400;
    font-size: 0.677vw;
    line-height: 1.3;
    color: var(--color-text-copy);

    .mobile &,
    .landscape &,
    .portrait & {
      right: 0;
      bottom: -2.5vw;
      font-size: 1.7vw;
    }
  }

  &-Wrapper {
    display: flex;
    justify-content: space-between;
  }

  &-DocsList {
    margin-bottom: -3vw;

    .landscape &,
    .portrait & {
      margin-bottom: -7vw;
    }

    .mobile & {
      margin-bottom: -5vw;
    }
  }

  &-Policy {
    font-family: "PTSans", sans-serif;
    font-size: 0.677vw;
    color: var(--color-text-copy);
    margin-right: 20px;
    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 1.7vw;
      margin-right: 10px;
    }
  }
}
</style>
