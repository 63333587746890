<template>
  <div class="Partners">
    <div class="Partners-Title">
      {{ partnersTitle }}
    </div>
    <PartnersSlider :slider-info="sliderInfo"/>
  </div>
</template>

<script>
import PartnersSlider from "@/components/PartnersSlider";

export default {
  name: "Partners",
  components: {
    PartnersSlider
  },
  props: {
    partnersTitle: {
      type: String,
      default: ""
    },
    sliderInfo: {
      type: [Object, Array],
      default: () => {
      },
    },
  },
}
</script>

<style lang="scss">
.Partners {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-Title {
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 1.50;
    text-transform: uppercase;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 2.6vw;
    }
  }
}
</style>