<template>
  <section
      class="Awards"
      :class="{'Awards--padding': isShowAwards}"
  >
    <h3 class="Awards-Title">
      Why you should choose us?
    </h3>
    <div class="Awards-Content">
      <img :src="require(`@/assets/images/${switchBgImage}`)" alt="" class="Awards-ContentImg">
      <div class="Awards-Wrapper">
        <div class="AdvantagesList">
          <div
              v-for="(advantage, $advantage) in advantagesList"
              :key="$advantage"
              class="Awards-AdvantageItem"
              :class="`Awards-AdvantageItem--${advantage.class}`"
          >
            <div class="Awards-AdvantageItemTitle">
              {{ advantage.title }}
            </div>
            <div class="Awards-AdvantageItemIndicator">
              {{ advantage.indicator }}
            </div>
            <div class="Awards-AdvantageItemText">
              {{ advantage.text }}
            </div>
          </div>
        </div>

        <div
            class="AwardsTop"
            :class="{'AwardsTop--column AwardsTop--padding': isMobile && !isShowAwards}"
        >
          <div
              class="Awards-AwardsTitle"
          >
            Our <br>rewards
          </div>
          <button
              v-show="!isShowAwards && isMobile"
              @click="isMobile ? isShowAwards = !isShowAwards : isShowAwards"
          >
            more
          </button>
          <img
              v-show="!isMobile || isShowAwards"
              src="@/assets/images/effie-awards-logo.svg"
              alt="effie awards logo"
              class="Awards-AwardsLogo"
          >
        </div>
        <div
            v-show="!isMobile"
            class="AwardsList"
        >
          <div
              v-for="(award, $award) in awardsList"
              :key="'A'+$award"
              class="Awards-AwardsItem"
              :class="`Awards-AwardsItem--${award.class}`"
          >
            <img src="@/assets/images/appsflyer.png" alt="icon" class="Awards-AwardsItemIcon">
            <div class="Awards-AwardsItemRank">
              - {{ award.ranking }} -
            </div>
            <p
                class="Awards-AwardsItemText"
                v-html="award.text"
            ></p>
            <img src="@/assets/images/leaf.png" alt="image" class="Awards-AwardsItemImg">
          </div>
        </div>
      </div>
      <transition name="slide">
        <div
            v-show="isMobile && isShowAwards"
            class="AwardsList"
        >
          <div
              v-for="(award, $award) in awardsList"
              :key="'A'+$award"
              class="Awards-AwardsItem"
              :class="`Awards-AwardsItem--${award.class}`"
          >
            <img src="@/assets/images/appsflyer.png" alt="icon" class="Awards-AwardsItemIcon">
            <div class="Awards-AwardsItemRank">
              - {{ award.ranking }} -
            </div>
            <p
                class="Awards-AwardsItemText"
                v-html="award.text"
            ></p>
            <img src="@/assets/images/leaf.png" alt="image" class="Awards-AwardsItemImg">
          </div>
          <img src="@/assets/images/awaeds-bottom.svg" alt="image" class="BottomImg">
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Awards",
  data() {
    return {
      isShowAwards: false
    }
  },
  computed: {
    ...mapGetters({
      advantagesList: "getAdvantagesList",
      awardsList: "getAwardsList",
      isMobile: 'isMobile',
    }),
    switchBgImage() {
      return this.isMobile ? 'advantages-bg-mobile.svg' : 'advantages-bg.png'
    }
  }
}
</script>

<style lang="scss">
.Awards {
  padding: 3.7vw 0 .416vw;
  background-color: var(--color-body1);

  .mobile &,
  .landscape &,
  .portrait & {
    padding: 4.907vw 0 6vw;

    &--padding {
      padding: 4.907vw 0 15vw;
    }
  }

  &-Title {
    margin-bottom: 2.24%;
    font-weight: 300;
    font-size: 3.4vw;
    text-align: center;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 1.17%;
      font-size: 4.21vw;
    }
  }

  &-Content {
    position: relative;
    width: 100%;

    .desktop & {
      height: 59.636vw;
    }
  }

  &-ContentImg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  &-Wrapper {
    position: relative;
    width: 87.188vw;
    height: 100%;
    margin: 0 auto;

    .mobile &,
    .landscape &,
    .portrait & {
      padding: 3.6vw 0 0;
    }
  }

  .AdvantagesList {
    .mobile &,
    .landscape &,
    .portrait & {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0 4vw;
      grid-template-areas:
      '. years .'
      'work . ftd'
      '. team .';
    }
  }

  &-AdvantageItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-text-main);

    &--years {
      color: var(--color-text-main2);

      .mobile &,
      .landscape &,
      .portrait & {
        grid-area: years;
      }

      .desktop & {
        position: absolute;
      }
    }

    .mobile &,
    .landscape &,
    .portrait & {
      width: 100%;
      &--work {
        grid-area: work;
        margin: -3vw 0 0 -6vw;
      }

      &--ftd {
        grid-area: ftd;
        margin: -3vw 0 0 6vw;
      }

      &--team {
        grid-area: team;
        margin-top: -4vw;
      }
    }


    .desktop & {
      position: absolute;

      &--years {
        top: 8%;
        left: 50%;
        transform: translate(-50%, 0);
        color: var(--color-text-main2);
      }

      &--work {
        top: 16%;
        left: 7%;
      }

      &--ftd {
        top: 16%;
        right: 0;
      }

      &--team {
        top: 31.5%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  &-AdvantageItemTitle {
    margin-bottom: 6%;
    font-weight: 300;
    font-size: 1.614vw;
    line-height: 1.15;
    text-transform: uppercase;

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 2%;
      font-size: 3.272vw;
    }
  }

  &-AdvantageItemIndicator {
    font-weight: 300;
    font-size: 4.062vw;
    line-height: 1;
    text-transform: uppercase;

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 5.85vw;
    }
  }

  &-AdvantageItemText {
    font-weight: 500;
    font-size: 1.25vw;
    line-height: 1.15;

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 2.804vw;
    }
  }

  .AwardsTop {
    > button {
      display: none;
    }

    &--column {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3vw 0;

      &--padding {
        padding: 10vw 0 4vw;
      }

      > button {
        display: block;
        margin-top: 4vw;
        padding: 1.154vw 6.026vw;
        font-size: 3.59vw;
        line-height: 1.5;
        text-align: center;
        text-transform: uppercase;
        color: var(--color-text-gold-dark);
        border: 1px solid var(--color-text-gold-dark);
      }
    }
  }

  &-AwardsLogo {
    width: 26.87vw;

    .desktop & {
      position: absolute;
      width: 12.136vw;
      top: 56%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &-AwardsTitle {
    font-weight: 300;
    font-size: 4.206vw;
    line-height: 1;
    color: var(--color-text-gold-dark);

    .desktop & {
      position: absolute;
      top: 42%;
      left: 0;
      font-size: 2.343vw;
      font-weight: 500;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      br {
        display: none;
      }
    }
  }

  .AwardsList {
    .mobile &,
    .landscape &,
    .portrait & {
      position: relative;
      display: grid;
      grid-gap: 12vw 9vw;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 6.6vw;
      background-color: var(--color-bg-dark);

      br {
        display: none;
      }

      > img {
        position: absolute;
        bottom: -14.5vw;
      }
    }
  }

  &-AwardsItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.15;
    text-align: center;
    color: var(--color-text-gold-light);

    .desktop & {
      position: absolute;
    }

    &--award1 {
      .desktop & {
        top: 53%;
        left: 0;
      }
    }

    &--award2 {

      .desktop & {
        top: 62%;
        left: 21.5%;
      }
    }

    &--award3 {

      .desktop & {
        top: 69%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    &--award4 {

      .desktop & {
        top: 62%;
        right: 21.5%;
      }
    }

    &--award5 {
      .desktop & {
        top: 53%;
        right: 0;
      }

      .mobile &,
      .landscape &,
      .portrait & {
        grid-column: 1 / span 2;
        grid-row: 5 / 3;
        justify-self: center;
        width: 35%;
      }
    }
  }

  &-AwardsItemIcon {
    width: 12.917vw;
    .mobile &,
    .landscape &,
    .portrait & {
      width: 35.1vw;
    }
  }

  &-AwardsItemRank {
    font-weight: 300;
    font-size: 2.135vw;
    text-transform: uppercase;

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 7.01vw;
    }
  }

  &-AwardsItemText {
    margin-bottom: 8.47%;
    font-weight: 500;
    font-size: .625vw;

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 2.804vw;
    }
  }

  &-AwardsItemImg {
    width: 5.157vw;
    .mobile &,
    .landscape &,
    .portrait & {
      width: 13.157vw;
    }
  }
}


</style>