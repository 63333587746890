<template>
  <div class="Field">
    <input
        :value="value"
        :placeholder="placeholder"
        class="Field-Input"
        :class="{'Input--error': v.$error}"
        @input="updateValue"
    >
    <span v-if="requiredValue"
          class="invalid-feedback">Required field!</span>
    <span v-if="email"
          class="invalid-feedback">Invalid email</span>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    placeholder: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    v: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    }
  },
  computed: {
    email() {
      return this.v && this.v.email === false && this.v.$dirty;
    },
    requiredValue() {
      return this.v && this.v.required === false && this.v.$dirty;
    },
  }
}
</script>

<style scoped>

</style>