<template>
  <form
      class="Form"
      novalidate
      @submit.prevent="onSubmit"
  >
    <div class="Form-Form">
      <div class="Form-Title">
        Write to us
        <img src="@/assets/images/envelop.svg" alt="envelop">
      </div>
      <div class="Form-Inputs">
        <div class="Form-InputsTop">
          <BaseInput
              type="text"
              v-model.trim="form.name.value"
              class="Form-Input"
              :placeholder="form.name.label"
              :label="form.name.label"
              :v="$v.form.name.value"
          />
          <BaseInput
              type="text"
              v-model.trim="form.email.value"
              class="Form-Input"
              :placeholder="form.email.label"
              :label="form.email.label"
              :v="$v.form.email.value"
          />
        </div>
        <BaseInput
            type="text"
            v-model.trim="form.phone.value"
            :placeholder="form.phone.label"
            :label="form.phone.label"
            :v="$v.form.phone.value"
        />
      </div>
      <BaseTextarea
          v-model="form.message.value"
          class="Form-Textarea"
          :label="form.message.label"
          :v="$v.form.message.value"
      />
      <button class="Btn Btn--red Form-Btn">
        Send
      </button>
    </div>
    <div class="Form-Contacts">
      <div class="Form-ContactsTitle">
        For all requests
      </div>
      <a v-for="(contact, $contact) in contacts.allRequirements"
         :key="'A' + $contact"
         :href="contact.link"
         target="_blank"
         class="Form-Contact"
      >
        {{ contact.title }}
      </a>

      <vue-recaptcha
          @verify="onVerify"
          sitekey="6LdHAXMfAAAAAHc5EdKz6UM2Hv4JhbAYNYarKb8r"
          class="Form-Captcha"
      ></vue-recaptcha>

    </div>
  </form>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {email, required} from "vuelidate/lib/validators";
import axios from "axios";
import {mapGetters} from "vuex";
import {VueRecaptcha} from 'vue-recaptcha';
import {showModal} from "@/assets/utils/utils";

export default {
  name: "Form",
  components: {VueRecaptcha},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: {
          value: '',
          label: 'Name*'
        },
        email: {
          value: '',
          label: 'Email*'
        },
        phone: {
          value: '',
          label: 'Phone*',
        },
        message: {
          value: '',
          label: 'Your message*',
        },
        robot: false
      },
    }
  },
  computed: {
    ...mapGetters({
      contacts: "getContacts"
    })
  },
  validations: {
    form: {
      name: {
        value: {
          required
        }
      },
      email: {
        value: {
          required,
          email,
        }
      },
      phone: {
        value: {
          required
        }
      },
      message: {
        value: {
          required
        }
      },
    }
  },
  methods: {
    showModal,
    onVerify(response) {
      if (response) this.form.robot = true;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$error /*&& this.form.robot*/) {
        const params = new URLSearchParams();
        params.append('name', this.form.name.value);
        params.append('email', this.form.email.value);
        params.append('phone', this.form.phone.value);
        params.append('message', this.form.message.value);

        axios.post(
            "/mail.php",
            params,
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              }
            }
        ).then(() => {
        })
        this.showModal()
        this.form.name.value = ''
        this.form.email.value = ''
        this.form.phone.value = ''
        this.form.message.value = ''
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    }
  }

}
</script>

<style lang="scss">
.Form {
  display: flex;
  width: 72.917vw;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  .mobile &,
  .landscape &,
  .portrait & {
    flex-direction: column;
    width: 84vw;
  }

  &-Form {
    width: percentage(975 / 1400);
    padding: 2.083vw 4.479vw 1.875vw;
    color: var(--color-text-main);
    background-color: var(--color-bg-main1-light);

    .mobile &,
    .landscape &,
    .portrait & {
      width: 100%;
      padding: 6.075vw 4.9vw 7.1vw;
    }
  }

  &-Inputs {
    margin-bottom: 2.187vw;

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 7.01vw;
    }
  }

  &-InputsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.604vw;

    .mobile &,
    .landscape &,
    .portrait & {
      flex-direction: column;
      margin-bottom: 7.01vw;
    }
  }

  &-Input {
    width: 100%;

    .desktop & {
      margin-right: 3vw;

      &:last-child {
        margin-right: 0;
      }
    }

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 7.01vw;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-Textarea {
    margin-bottom: 2.656vw;

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 7vw;
    }
  }

  &-Btn {
    position: relative;
    padding: .661vw 4.841vw .655vw;
    left: 50%;
    transform: translate(-50%, 0);

    .mobile &,
    .landscape &,
    .portrait & {
      padding: 2.661vw 14.841vw 2.655vw;
    }
  }

  &-Title {
    display: flex;
    align-items: center;
    margin-bottom: 2.291vw;
    font-size: 1.302vw;
    text-transform: uppercase;

    img {
      width: 1.875vw;
      margin: 0 0 .26vw .52vw;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      margin-bottom: 5.6vw;
      font-size: 3.74vw;

      img {
        width: 4.44vw;
        margin: 0 0 .7vw 1vw;
      }
    }
  }

  &-Contacts {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 22.59vw;
    padding: 2.083vw 1.77vw 2.083vw 1.98vw;
    font-size: 1.302vw;
    text-transform: lowercase;
    background-color: var(--color-body);

    .mobile &,
    .landscape &,
    .portrait & {
      width: 100%;
      padding: 28.8vw 4.9vw 7.1vw;
      font-size: 3.74vw;
    }
  }

  &-ContactsTitle {
    margin-bottom: .52vw;
    font-weight: 300;
    font-size: 1.041vw;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 2.804vw;

      &--margin {
        margin-top: 6.562vw;
      }
    }
  }

  &-Contact {
    position: relative;
    z-index: 2;
    margin-bottom: .26vw;
    color: var(--color-text-main1-light);

    &:before {
      position: absolute;
      content: "";
      bottom: .06vw;
      left: 0;
      width: 100%;
      height: .104vw;
      background-color: var(--color-text-main1-light);
      opacity: 0;
      transition: opacity .3s;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &-Captcha {
    position: absolute;
    right: 5%;
    bottom: 5%;

    .mobile &,
    .landscape &,
    .portrait & {
      top: 5%;
      left: 5%;
    }
  }
}

</style>