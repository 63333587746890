<template>
  <div class="SliderBox">
    <swiper :options="options"
            class="Slider"
    >
      <swiper-slide
          v-for="(icon, $icon) in sliderInfo"
          :key="$icon"
          class="Slider-Slide"
      >
        <img
            :src="require(`@/assets/images/${icon}`)"
            alt="partners icons"
            :class="'Slider-Slide--hover'"
        >
      </swiper-slide>
    </swiper>
    <img src="@/assets/images/chevron-left.svg" alt="" class="Slider-Btn Prev" slot="button-prev">
    <img src="@/assets/images/chevron-right.svg" alt="" class="Slider-Btn Next" slot="button-next">
  </div>
</template>

<script>
import {Navigation} from "swiper";
import {mapGetters} from "vuex";

export default {
  name: "PartnersSlider",
  props: {
    sliderInfo: {
      type: [Object, Array],
      default: () => {
      },
    },
  },
  data() {
    return {
      options: {
        modules: [Navigation],
        slidesPerView: 'auto',
        slidesPerGroup: 5,
        speed: 700,
        freeMode: true,
        navigation: {
          nextEl: '.Next',
          prevEl: '.Prev'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
  },
}
</script>

<style lang="scss">

.SliderBox {
  width: 65.1041vw;
  position: relative;
  margin-right: 2%;

  .mobile &,
  .landscape &,
  .portrait & {
    width: 56vw;
  }
}

.Slider {
  width: 59.896vw;
  margin: 0 auto;
  overflow: hidden;

  .mobile &,
  .landscape &,
  .portrait & {
    width: 43.5vw;
  }

  &-Slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vw !important;
    height: 6vw !important;
    margin-right: 2vw;


    img {
      border-radius: 20%;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      width: 8vw !important;
      height: 10vw !important;
      margin-right: 3.74vw;
    }
  }

  &-Slide--hover {
    &:hover {
      transform: scale(1.24);
    }
  }

  &-Btn {
    position: absolute;
    width: 2.7%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 100;
    cursor: pointer;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 3.7%;
    }
  }
}

.Prev {
  left: 0;
}

.Next {
  right: 0;
}
</style>