<template>
  <div class="home">
    <Header />
    <Description />
    <Awards />
    <FormSection />
  </div>
</template>

<script>

import Header from "@/components/Header";
import Description from "@/components/Description";
import Awards from "@/components/Awards";
import FormSection from "@/components/FormSection";

export default {
  name: 'Home',
  components: {
    Awards,
    Description,
    Header,
    FormSection,
  },
  metaInfo: {
    title: 'Monterizo',
    /*meta: [{
      vmid: 'description',
      name: 'description',
      content:"WakeApp is the UK's Best Performing Mobile Marketing Agency ✅ 8+ Years Expertise ✅ 300+ Professional Who Work for Result ✅ More Than 500 000 FTD Per Year",
    }],*/
  }
}
</script>

<style lang="scss">
/*.home {
  .mobile &,
  .landscape &,
  .portrait & {
    padding: 2.2vw 0 0;
  }
}*/
</style>
