<template>
  <section
      class="FormSection"
      :class="[
          {'FormSection--bg-color': $route.name === 'user-acquisition'},
          {'FormSection--padding': $route.name === 'contacts'},
      ]"
  >
    <h3 class="FormSection-Title">
      Do you have questions?
    </h3>
    <div
        v-if="$route.name !== 'contacts'"
        v-show="!showForm"
        class="FormSection-Content"
    >
      <button
          class="Btn Btn--white FormSection-Btn"
          @click="showForm = !showForm"
      >
        CONTACT US
      </button>
    </div>
    <transition name="fade">
      <Form v-show="showForm || $route.name === 'contacts'"/>
    </transition>
    <transition name="fade">
      <img
          v-show="showForm || $route.name === 'contacts'"
          src="@/assets/images/form-bg-red.png"
          alt="bg"
          class="FormSection-Bg"
      >
    </transition>
  </section>
</template>

<script>
import Form from "@/components/Form";

export default {
  name: "FormSection",
  components: {Form},
  data() {
    return {
      showForm: false,
    }
  },
}
</script>

<style lang="scss">
.FormSection {
  position: relative;
  padding: 5vw 0 5.312vw;
  background: url(~@/assets/images/form-bg-white.png) var(--color-body1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;

  .mobile &,
  .landscape &,
  .portrait & {
    padding: 7.95vw 0 8.312vw;
  }

  &--bg-color {
    background-color: var(--color-body);
  }

  &--padding {
    padding: 1.05vw 0 6.3vw;
  }

  &-Bg {
    position: absolute;
    z-index: 0;
    bottom: -8vw;
  }

  &-Title {
    margin-bottom: 3.021vw;
    font-weight: 300;
    font-size: 3.385vw;
    line-height: 150%;
    text-align: center;
    color: var(--color-text-main2);

    .mobile &,
    .landscape &,
    .portrait & {
      font-size: 4.206vw;
    }
  }

  &-Content {
    position: relative;
    width: 16.45vw;
    min-height: 6.1vw;
    margin: 0 auto;

    .mobile &,
    .landscape &,
    .portrait & {
      width: 31.45vw;
      min-height: 10.3vw;
    }
  }

  &-Btn {
    width: 100%;
    padding: 1.042vw 0 1vw;

    &:after {
      position: absolute;
      content: "";
      bottom: .06vw;
      left: 0;
      width: 100%;
      height: 2.2vw;
      border-left: 8.2vw solid transparent;
      border-right: 8.2vw solid transparent;
      border-top: 2.2vw solid var(--color-bg-main1);
    }
    .mobile &,
    .landscape &,
    .portrait & {
      padding: 2.042vw 0 2vw;

      &:after {
        bottom: -0.94vw;
        height: 3.2vw;
        border-left: 15.7vw solid transparent;
        border-right: 15.5vw solid transparent;
        border-top: 3.2vw solid var(--color-bg-main1);
      }
    }
  }
}

</style>