<template>
  <div class="NavBar">
    <router-link
        v-for="(link, $link) in getNav"
        :key="$link"
        :to="link.to"
        class="NavBar-Link"
        @click.native="$emit('open-menu')"
    >
      {{ link.name }}
    </router-link>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NavBar",
  computed: {
    ...mapGetters(['getNav'])
  }
}
</script>

<style lang="scss">
.NavBar {
  display: flex;
  align-items: center;

  .mobile &,
  .landscape &,
  .portrait & {
    position: absolute;
    top: 0;
    z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    padding: 5.374vw 3.97vw 3.97vw;
    background-color: var(--color-input-border2);
  }

  &-Link {
    margin-right: 22%;
    white-space: nowrap;
    font-weight: 500;
    font-size: 1.32vw;
    line-height: 1;
    transition: .3s;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: var(--color-bav-hover);
    }

    .mobile &,
    .landscape &,
    .portrait & {
      margin-right: 0;
      margin-bottom: 2.337vw;
      font-size: 3.74vw;
      color: var(--color-mobile-link);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .router-link-active {
    color: var(--color-bav-hover);
  }
}
</style>