<template>
  <section class="Header">
    <img :src="require(`@/assets/images/${changeImg}`)" alt="bg" class="Header-Bg">
    <h1 class="Header-Title">
      <img src="@/assets/images/logo-title.png" alt="" class="Header-TitleImg">
    </h1>
    <div class="Wrapper">
      <About/>
    </div>
  </section>
</template>

<script>
import About from "@/components/About";
import {mapGetters} from "vuex";

export default {
  name: "Header",
  components: {About},
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    changeImg() {
      console.log()
      return this.isMobile ? 'header-bg.png' : 'header-bg.png'
    }
  },
}
</script>

<style lang="scss">
.Header {
  position: relative;
  padding: 4% 0 9.8%;
  background-color: var(--color-body1);

  .mobile & {
    padding: 13.3% 0 9.8%;
  }

  &-Bg {
    position: absolute;
    width: 100%;
  }

  &-Title {
    position: relative;
    z-index: 2;
    width: 60.42%;
    max-width: 100%;
    margin: 0 auto;
    padding: 14% 0 16%;

    img {
      width: 100%;
      height: 100%;
    }

    .mobile &,
    .landscape &,
    .portrait & {
      width: 59.6%;
    }
  }

  &-Text {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37.2%;
    height: 21%;
    top: 53.9%;
    left: 50.4%;
    background: var(--color-bg-main1);
    font-weight: 300;
    font-size: 2.78vw;
    line-height: 1;
    letter-spacing: 0.02em;
    color: var(--color-text-main);

    .mobile &,
    .landscape &,
    .portrait & {
      top: 50.3%;
      left: 51.4%;
      font-size: 3.06vw;
    }
  }

}
</style>