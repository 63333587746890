<template>
  <div class="MainMenu">
    <div class="Wrapper MainMenu-Wrapper">
      <button
          v-if="!isDesktop"
          :class="{'Button--opacity': isOpen}"
          @click="openMenu"
      >
        <img src="@/assets/images/burger.svg" alt="">
      </button>
      <NavBar
          v-if="isDesktop || isOpen"
          class="MainMenu-NavBar"
          @open-menu="openMenu"
          v-click-outside="onClickOutside"
      />
      <Logo class="MainMenu-Logo">
        <img :src="require(`@/assets/images/logo.png`)" alt="logo">
      </Logo>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo";
import NavBar from "./NavBar";
import {mapGetters} from "vuex";

export default {
  name: "MainMenu",
  components: {NavBar, Logo},
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'isDesktop'
    })
  },
  methods: {
    openMenu() {
      this.isOpen = !this.isOpen
    },
    onClickOutside(e) {
      if (!e.target.closest('.MainMenu-NavBar')) this.isOpen = false;
    },
  }
}
</script>

<style lang="scss">
.MainMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.324% 0;
  background: var(--color-body);
  box-shadow: 0px 6px 8px rgba(82, 0, 107, 0.08);

  .mobile &,
  .landscape &,
  .portrait & {
    padding: 3.6% 0;

    .Button--opacity {
      opacity: 0;
    }
  }

  &-Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-Logo {
    margin-right: 15%;

    .mobile &,
    .landscape &,
    .portrait & {
      margin-right: 0;
    }
  }
}

</style>