<template>
  <div class="Field">
    <label class="Text Text--dark Text--small Field-Label">
      {{ label }}
    </label>
    <textarea
        :value="value"
        :placeholder="placeholder"
        class="Field-Input Field-Textarea"
        :class="{'Input--error': v.$error}"
        @input="updateValue"
    ></textarea>
    <span v-if="v.$dirty && !v.required"
          class="invalid-feedback">Required field!</span>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    placeholder: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    v: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    }
  },
}
</script>

<style scoped>

</style>